body {
  background-color: #0d0d16;
}
body::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.cardScroll::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.cardScroll {
  scrollbar-width: none;
}
html, body {
  /* overflow-y: scroll; */
  scrollbar-width: none;
}
svg {
  display: inline-block;
  vertical-align: middle;
}
.scrollbar {
  overscroll-behavior-x: none;
}
.gradientBtn {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.gradientBtn::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(75deg, #bc00c9, #7700a3);
  z-index: -1;
  border-radius: inherit;
}
.gradientBtn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #0d0d16;
  z-index: -1;
  border-radius: 8px;
}
html,
body,
#app,
#app > div {
  height: 98vh;
}
